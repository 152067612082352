import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './ArtistChecker.css';

export const partifulPassword = process.env.REACT_APP_PARTIFUL_PASSWORD;

const ArtistChecker = ({ token }) => {
  const [isTopArtist, setIsTopArtist] = useState(null);
  const [firstName, setFirstName] = useState("");
  const hardcodedArtistName = "Seedhe Maut";

  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        const response = await axios.get("https://api.spotify.com/v1/me", {
          headers: {
            "Authorization": `Bearer ${token}`
          }
        });
        setFirstName(response.data.display_name.split(' ')[0]);
      } catch (error) {
        console.error("Error fetching user profile", error);
      }
    };

    const checkArtist = async () => {
      try {
        const response = await axios.get("https://api.spotify.com/v1/me/top/artists", {
          headers: {
            "Authorization": `Bearer ${token}`
          }
        });
        const topArtists = response.data.items;
        const found = topArtists.some(artist => artist.name.toLowerCase() === hardcodedArtistName.toLowerCase());
        setIsTopArtist(found);
      } catch (error) {
        console.error("Error fetching top artists", error);
      }
    };

    fetchUserProfile();
    checkArtist();
  }, [token]);

  return (
    <div className="container">
      <p className="greeting">GM, {firstName}!</p>
      
      {isTopArtist === null
        ? <p className="message">Checking if {hardcodedArtistName} is among your top artists...</p>
        : (
            <>
              {isTopArtist 
                ? (
                  <>
                    <p className="message">Yes, {hardcodedArtistName} is among your top artists. You can now go ahead and RSVP <a href="https://partiful.com/e/76NckJndFGgTzKZaH3m1">here</a>. The password is <b>{partifulPassword}</b>.</p>
                  </>
                )
                : (
                  <>
                    <p className="message">No, {hardcodedArtistName} isn't among your top artists.</p>
                    <img className="artist-image" src="/lunchbreak.jpeg" alt="Description" />
                  </>
                )
              }
            </>
          )
      }
    </div>
);
};

export default ArtistChecker;
